export const dataDigitalBestSeller = [
    {
      id: 1,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Conjunto residencial apartamentos',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/1.jpg',
    },
    {
      id: 2,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Conjunto residencial apartamentos',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/2.jpg',
    },
    {
      id: 3,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Conjunto residencial apartamentos',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/3.jpg',
    },
    {
      id: 4,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Conjunto residencial apartamentos',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/4.jpg',
    },
    {
      id: 5,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Viviendas',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/5.jpg',
    },
    {
      id: 6,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Viviendas',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/6.jpg',
    },
    {
      id: 7,
      title: 'Guadua Eco Hábitat',
      price: '$135.000.000',
      category: 'Viviendas',
      linkImg:
        'https://meetrico.com/felipevelasquezarquitecto/images/7.jpg',
    },
    {
        id: 7,
        title: 'Guadua Eco Hábitat',
        price: '$135.000.000',
        category: 'Apartamentos',
        linkImg:
          'https://meetrico.com/felipevelasquezarquitecto/images/8.jpg',
      },
      {
        id: 7,
        title: 'La Esneda Trujillo',
        price: '$100.000.000',
        category: 'Viviendas',
        linkImg:
          'https://meetrico.com/felipevelasquezarquitecto/images/9.jpg',
      },

  ];