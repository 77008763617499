import React from 'react'

export default function Body() {
  return (
    <section className="hero is-light is-halfheight">
<div className="hero-body">
  
   
<p className="title">

Asesoro tu proyecto en todas las fases desde el diseño hasta la construcción. 
<hr></hr>
En la fase de diseño incorporamos<br></br>
<li>Sistemas de reciclaje de agua</li>
<li>Ventilación pasiva</li>
<li>Huertas urbanas</li>
<li>Economía circular</li>
<li>Páneles solares</li>  
<hr></hr>
<p className='title'>Estratégias que economizarán la administración de tu edificio. </p>       
</p>

</div>
</section>
  )
}
