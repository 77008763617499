import React from 'react'
import '../VideoStyles.css'
import video2 from '../assets/Video2.mp4'

function Video2() {
  return (
    <div className="hero">
        <video autoPlay loop muted id='video'>
            <source src={video2} type='video/mp4' />
        </video>
    </div>
  )
}

export default Video2