import React from 'react'
import '../VideoStyles.css'
import videoCuencas from '../assets/Video-cuencas.mp4'

export const Video = () => {
  return (
    <div className="hero">
        <video autoPlay loop muted id='video'>
            <source src={videoCuencas} type='video/mp4' />
        </video>
    </div>
  )
}
