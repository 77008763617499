

import { PrimerComponente } from './components/PrimerComponente';
import Header from './components/Header';
import Footer from './components/Footer';
import Slider from 'react-slick';
import './index.css';
import 'bulma/css/bulma.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { dataDigitalBestSeller } from './components/data';
import { Video } from './components/Video';
import SimpleSlider from './components/SimpleSlider';
import Video2 from './components/Video2';
import Body from './components/Body';
import Body1 from './components/Body1';
import Body2 from './components/Body2';
import SimpleSlider2 from './components/SimpleSlider2';
import Body3 from './components/Body3';
import SimpleSlider3 from './components/SimpleSlider3';




function App() {
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 6,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };
  return (
    <div className="App">
      <header className="App-header">
        <Header/>
        <PrimerComponente/>
        <Video></Video>
        <Body/>   
        <Slider {...settings}>
        {dataDigitalBestSeller.map((item) =>
         <div className="Card">
          <img src={item.linkImg} alt={item.title}/>
          <p className="subtitle">{item.title}</p>
         <div className="Card-top">
         <div className="Card-botton">
          <p className="subtitle">{item.category}</p>
 
         </div>
         </div>
         </div>
        )}
        </Slider>  
        
        <Body1 />
        <Video2 />
        <Body2 />
        <SimpleSlider/> 
        <Body3 />
        <SimpleSlider2/>
        <SimpleSlider3/>
        

      </header>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
}

export default App;
