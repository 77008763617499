

import React, { Component } from "react";
import Slider from "react-slick";
import '../VideoStyles.css'


export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };
    return (
      <div>
        <h2> Proyectos de vivienda </h2>
        <Slider {...settings}>
          <div>
            <img src="https://meetrico.com/felipevelasquezarquitecto/images/10.jpg" ></img>
            <p className="subtitle">La Esneda Trujillo</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/11.jpg" ></img>
          <p className="subtitle">La Esneda Trujillo</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/12.jpg" ></img>
          <p className="subtitle">La Esneda Trujillo</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/13.jpg" ></img>
          <p className="subtitle">Café Caicedonia</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/14.jpg" ></img>
          <p className="subtitle">Café Caicedonia</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/15.jpg" ></img>
          <p className="subtitle">Café Caicedonia</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/16.jpg" ></img>
          <p className="subtitle">Café Caicedonia</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/17.jpg" ></img>
          <p className="subtitle">Flor de Café</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/18.jpg" ></img>
          <p className="subtitle">Flor de Café</p>
            <p className="subtitle">Vivienda</p>
          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/20.jpg" ></img>
          <p className="subtitle">Flor de Café</p>
            <p className="subtitle">Vivienda</p>
          </div>
          
        </Slider>
      </div>
    );
  }
}