import React from 'react'

export default function Body1() {
  return (
    <section className="hero is-light is-halfheight">
<div className="hero-body">
  
   
<p className="title">

He diseñado 970 unidades de vivienda autosostenibles a lo largo de todo mi ejercicio profesional con avales de la Universidad de Harvard, la OEA, la Sociedad Colombiana de Arquitectos y firmas de Arquitectura a nivel nacional e internacional.       

</p>
</div>
</section>
  )
}
