import React, { Component } from "react";
import Slider from "react-slick";


export default class SimpleSlider3 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 3
    };
    return (
      <div>
        <h2> Con el apoyo de: </h2>
        <Slider {...settings}>
        
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/1.jpg" ></img>

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/2.jpg" ></img>

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/3.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/4.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/5.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/6.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/7.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/8.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/9.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/10.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/11.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/12.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/13.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/14.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/15.jpg" ></img>
          

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/apoyos/16.jpg" ></img>
          

          </div>
        </Slider>
      </div>
    );
  }
}