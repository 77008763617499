import React from 'react';
import '../index.css'

const Footer = () => {
    return (
<footer className="footer">
      <div className="menu">
        <h3>Lapapaya.org</h3>
        <ul>
          <li><a href="#">Link 1</a></li>
          <li><a href="#">Link 2</a></li>
          {/* Add more links as needed */}
        </ul>
      </div>
      <div className="menu">
        <h3>Meetrico.com</h3>
        <ul>
          <li><a href="#">Link 1</a></li>
          <li><a href="#">Link 2</a></li>
          {/* Add more links as needed */}
        </ul>
      </div>
      {/* Add more menu sections if necessary */}
    </footer>
    );
}

export default Footer;