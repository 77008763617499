import React from 'react'

export default function Body3() {
  return (
    <section className="hero is-light is-halfheight">
<div className="hero-body">
  
   
<p className="title">

Todos nuestros proyectos aportan positivamente a la construcción de una economía baja en emisiones de gases de efecto invernadero. 

</p>
</div>
</section>
  )
}
