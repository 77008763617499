import React from 'react';
import img from '../assets/logo2.png'
import '../VideoStyles.css'
import videoCuencas from '../assets/Video-cuencas.mp4'

const Header = () => {
    return (
        <div className="hero">
            <header className="header">
      <nav className="navbar">
        <div className="logo"><img className src='https://meetrico.com/felipevelasquezarquitecto/images/logo2.png'></img></div>
        <ul className="nav-links">
          <li><a href="#">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Services</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </nav>
    </header>
    </div>
    );
}

export default Header;

