import React, { Component } from "react";
import Slider from "react-slick";


export default class SimpleSlider2 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };
    return (
      <div>
        <h2> Comerciales: </h2>
        <Slider {...settings}>
        <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/21.jpg" ></img>

          </div>
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/22.jpg" ></img>

          </div>
 
          <div>
          <img src="https://meetrico.com/felipevelasquezarquitecto/images/24.jpg" ></img>
          

          </div>
        </Slider>
      </div>
    );
  }
}