import React from 'react'

export default function Body2() {
  return (
    <section className="hero is-light is-halfheight">
<div className="hero-body">
  
   
<p className="title">

Los premios verdes Latinoamérica nos catalogan como una de las 500 iniciativas de emprendimiento más sostenibles del continente ocupando el puesto 125 y el puesto 35 en Colombia en la categoría Economía Circular.     

</p>
</div>
</section>
  )
}
